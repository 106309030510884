<template lang="">
    <div class="question-item">
        <h4>
            {{ $t('test_page.question') }} {{ order }}
            <span class="editor-custom" v-html="question.title"></span>
        </h4>
        <MyEditor :data="question.problem_statement" :isReadOnly="true" />
        <div class="explain" v-if="type === 'RESULT' && question.explanation">
            <v-icon class="fa-solid fa-right-long"></v-icon>
            <MyEditor :data="question.explanation" :isReadOnly="true" />
        </div>
        <div class="answer" v-if="question.multiple_choice === 1">
            <a-radio-group @change="onChangeRadio" :defaultValue="type === 'RESULT' ? handleDefaultValue() : ''">
                <div v-for="(answer, index) in question.test_answers">
                    <a-radio
                        :value="answer.id"
                        :key="answer.id"
                        :class="handleRenderClass(answer)"
                        :disabled="type === 'RESULT'"
                    >
                        <div class="d-flex">
                            <MyEditor :data="answer.description" :isReadOnly="true" />
                            <v-icon class="fa-solid fa-check icon-correct"></v-icon>
                            <v-icon class="fa-solid fa-xmark icon-wrong"></v-icon>
                        </div>
                    </a-radio>
                    <div class="explain" v-if="type === 'RESULT' && answer.explanation">
                        <v-icon class="fa-solid fa-right-long"></v-icon>
                        <p class="text-break">{{ answer.explanation }}</p>
                    </div>
                </div>
            </a-radio-group>
        </div>
        <div class="answer" v-else-if="question.multiple_choice !== 1">
            <a-checkbox-group @change="onChangeCheckbox" :defaultValue="type === 'RESULT' ? handleDefaultValue() : []">
                <div v-for="answer in question.test_answers">
                    <a-checkbox
                        :value="answer.id"
                        :key="answer.id"
                        :class="handleRenderClass(answer)"
                        :disabled="type === 'RESULT'"
                    >
                        <div class="d-flex">
                            <MyEditor :data="answer.description" :isReadOnly="true" />
                            <v-icon class="fa-solid fa-check icon-correct"></v-icon>
                            <v-icon class="fa-solid fa-xmark icon-wrong"></v-icon>
                        </div>
                    </a-checkbox>
                    <div class="explain" v-if="type === 'RESULT' && answer.explanation">
                        <v-icon class="fa-solid fa-right-long"></v-icon>
                        <p>{{ `${answer.explanation}` }}</p>
                    </div>
                </div>
            </a-checkbox-group>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MyEditor from '../../CMS/MyEditor';

export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        order: {
            type: Number,
            required: true,
        },
        question: {
            type: Object,
            required: true,
        },
    },
    components: {
        MyEditor,
    },
    computed: {
        ...mapState('recordWeb', ['recordObject']),
    },
    methods: {
        ...mapActions('testWeb', ['addUserAnswer']),

        handleRenderClass(answer) {
            if (this.type === 'TEST') return '';

            if (this.recordObject) {
                const isCorrect = this.recordObject?.answers?.find((item) => item === answer.id);

                if (isCorrect) return 'correct';

                const questionRecord = this.recordObject?.test_question_records?.find(
                    (item) => item.test_question_id === this.question.id,
                );
                const answerRecord = questionRecord?.test_answer_records?.find((item) => item.test_ans === answer.id);

                if (answerRecord !== undefined) {
                    return answerRecord?.is_corre ? 'correct' : 'wrong';
                } else {
                    return '';
                }
            }
        },

        handleDefaultValue() {
            if (this.recordObject?.test_question_records) {
                const questionRecord = this.recordObject?.test_question_records?.find(
                    (item) => item.test_question_id === this.question.id,
                );

                return this.question.multiple_choice === 1
                    ? questionRecord?.test_answer_records?.[0]?.test_ans
                    : questionRecord?.test_answer_records?.map((item) => item.test_ans);
            }
        },

        onChangeRadio(e) {
            this.addUserAnswer({
                question_id: this.question.id,
                answer_id: [e.target.value],
                multiple_choice: this.question.multiple_choice,
            });
        },

        onChangeCheckbox(values) {
            this.addUserAnswer({
                question_id: this.question.id,
                answer_id: values,
                multiple_choice: this.question.multiple_choice,
            });
        },
    },
};
</script>

<style lang="scss">
.question-item {
    background-color: #fff;
    padding: 15px;
    border-radius: 15px;
    & h4,
    & p {
        font-size: 16px;
    }
}

.answer {
    margin-top: 28px;

    & .ant-radio-group,
    & .ant-checkbox-group,
    & .ant-checkbox-wrapper {
        width: 100%;
    }

    & .ant-radio-wrapper,
    & .ant-checkbox-wrapper {
        display: flex !important;
        height: auto !important;
        line-height: 1 !important;
        margin-left: 0;
        padding: 10px;
        font-size: 16px;
        transition: all 0.3s ease;
        white-space: unset;

        &:hover {
            background-color: #ececec;
            border-radius: 5px;
        }

        & i {
            display: none;
            margin-left: 5px;
            font-size: 18px;
            color: inherit;
        }
    }

    & .ant-radio,
    & .ant-checkbox {
        height: max-content;
        margin-top: 3px;
    }

    & .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
        border-color: #1890ff !important;
    }

    & .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #fff;
    }

    & .ant-radio-disabled.ant-radio-checked .ant-radio-inner {
        border-color: #1890ff !important;
    }

    & .ant-radio-disabled.ant-radio-checked .ant-radio-inner::after {
        background-color: #1890ff;
    }

    & .correct {
        & span {
            color: #4caf50 !important;
        }
        & .icon-correct {
            margin-top: 2px;
            display: inline-block;
        }
    }

    & .wrong {
        & span {
            color: #ff5252 !important;
        }
        & .icon-wrong {
            margin-top: 2px;
            display: inline-block;
        }
    }
}

.explain {
    display: flex;
    align-items: flex-start;
    padding: 5px 10px;
    & i {
        font-size: 18px !important;
    }
    & p {
        margin: 0 0 10px 5px;
        font-size: 14px;
        color: #666;
        white-space: pre-line;
    }
}
</style>
