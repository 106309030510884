<template lang="">
    <div class="progress">
        <a-progress :percent="percent" :show-info="false" />
        <span>{{ text }}</span>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapState('testWeb', ['testObject', 'userAnswers']),
        ...mapState('recordWeb', ['recordObject']),
    },
    data() {
        return {
            percent: 0,
            text: '',
        };
    },
    created() {
        this.handleProgress();
    },
    watch: {
        userAnswers: function () {
            this.percent = (100 / this.testObject?.test_questions?.length) * this.userAnswers?.length;
            this.text = `${this.userAnswers?.length}/${this.testObject?.test_questions?.length}`;
        },
    },
    methods: {
        handleProgress() {
            if (this.type === 'TEST') {
                this.percent = (100 / this.testObject?.test_questions?.length) * this.userAnswers?.length;
                this.text = `${this.userAnswers?.length}/${this.testObject?.test_questions?.length}`;
            } else {
                this.percent =
                    (100 / this.testObject?.test_questions?.length) * this.recordObject?.test_question_records?.length;
                this.text = `${this.recordObject?.test_question_records?.length}/${this.testObject?.test_questions?.length}`;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.progress {
    margin-top: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & span {
        margin-left: 16px;
        font-size: 13px;
        font-weight: 600;
        color: #29313a;
    }
}
</style>
