<template lang="">
    <div class="question-list">
        <div class="item" v-for="(question, index) in testObject.test_questions" :key="question.id" :id="question.id">
            <QuestionItem :type="type" :order="index + 1" :question="question" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import QuestionItem from './QuestionItem.vue';

export default {
    props: {
        type: {
            type: String,
        },
    },
    components: {
        QuestionItem,
    },
    computed: {
        ...mapState('testWeb', ['testObject', 'userAnswers']),
    },
};
</script>

<style scoped lang="scss">
.question-list {
    & .item {
        margin-top: 10px;
    }
}
</style>
