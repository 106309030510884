<template lang="">
    <div class="time">
        <v-icon class="fa-regular fa-clock"></v-icon>
        <countdown
            ref="countdown"
            :time="time * 60 * 1000"
            :auto-start="start"
            :transform="transformSlotProps"
            @end="handleEnd"
        >
            <p slot-scope="props">{{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}</p>
        </countdown>
    </div>
</template>

<script>
import Vue from 'vue';
import VueCountdown from '@chenfengyuan/vue-countdown';

Vue.component(VueCountdown.name, VueCountdown);

export default {
    components: {
        VueCountdown,
    },

    props: {
        start: {
            type: Boolean,
            required: true,
        },
        time: {
            type: Number,
            required: true,
        },
        isStop: {
            type: Boolean,
            required: false,
        },
        onTimeOut: {
            type: Function,
            required: false,
        },
        onSetWorkTime: {
            type: Function,
            required: false,
        },
    },

    watch: {
        isStop: function () {
            if (this.isStop) {
                this.$refs.countdown.pause();
                const totalTime = this.time * 60 * 1000;
                const timeRemaining = this.$refs.countdown.totalMilliseconds;
                this.onSetWorkTime((totalTime - timeRemaining) / 1000);
            } else {
                this.$refs.countdown.continue();
                this.onSetWorkTime(0);
            }
        },
    },

    methods: {
        handleEnd() {
            this.onTimeOut();
        },
        transformSlotProps(props) {
            const formattedProps = {};

            Object.entries(props).forEach(([key, value]) => {
                formattedProps[key] = value < 10 ? `0${value}` : String(value);
            });

            return formattedProps;
        },
    },
};
</script>
<style scoped lang="scss">
.time {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    & i {
        color: #000;
    }
    & p {
        margin-bottom: 0;
        margin-left: 12px;
        color: rgb(38, 192, 72);
        font-size: 16px;
        font-weight: bold;
    }
}
</style>
