<template lang="">
    <div class="menu-question">
        <h2>{{ testObject.title }}</h2>
        <div class="box-question">
            <h4>{{ $t('test_page.list_of_questions') }}</h4>
            <div class="list">
                <button
                    v-for="(question, index) in testObject.test_questions"
                    :key="question.id"
                    :class="handleRenderClass(question)"
                    @click="scrollToEl(question.id)"
                >
                    {{ index + 1 }}
                </button>
            </div>
            <Progress :type="type" />
            <div class="submit" v-if="isSubmitTest" @click="onSubmitTest">
                <button>{{ $t('form.submit') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Progress from '../Progress/Progress.vue';

export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        isSubmitTest: {
            type: Boolean,
            required: true,
        },
        onSubmitTest: {
            type: Function,
        },
    },
    components: {
        Progress,
    },
    computed: {
        ...mapState('testWeb', ['testObject', 'userAnswers']),
        ...mapState('recordWeb', ['recordObject']),
    },
    methods: {
        scrollToEl(id) {
            const scrollDiv = document.getElementById(id);
            if (scrollDiv) {
                const position = scrollDiv.offsetTop;
                window.scrollTo({ top: position, behavior: 'smooth' });
            }
        },

        handleRenderClass(question) {
            if (this.type === 'TEST') {
                const index = this.userAnswers.findIndex((answer) => answer.question_id === question.id);
                return index !== -1 ? 'done' : '';
            } else if (this.type === 'RESULT') {
                if (this.recordObject?.test_question_records) {
                    const questionRecord = this.recordObject?.test_question_records?.find(
                        (item) => item.test_question_id === question.id,
                    );

                    if (questionRecord !== undefined) {
                        return questionRecord?.is_correct ? 'correct' : 'wrong';
                    }

                    return '';
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.menu-question {
    & h2 {
        font-size: 30px;
    }
}

.box-question {
    padding: 16px 22px;
    background: #fff;
    border-radius: 15px;
    & h4 {
        font-size: 16px;
        font-weight: normal;
    }
    & .list {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        justify-items: center;
        gap: 5px;
        width: 100%;
        margin-top: 16px;
        & button {
            min-width: unset;
            padding: unset;
            font-family: inherit;
            border-radius: 10px;
            background: #f2f3f7;
            color: #777;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 5px;
            width: 100%;
            border: 2px solid #f2f3f7;
        }
        & button:focus {
            background: #fff;
            color: #1d1d1d;
            border-color: #007aff;
        }
        & button.done {
            background: #007aff;
            color: #fff;
            border-color: #007aff;
        }
        & button.correct {
            background: #4caf50;
            color: #fff;
            border-color: #4caf50;
        }
        & button.wrong {
            background: #ff5252;
            color: #fff;
            border-color: #ff5252;
        }
    }

    & .submit {
        margin-top: 20px;
        & button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            background: #e4e6ed;
            color: #383838;
            border: 2px solid #e4e6ed;
            border-radius: 20px;
            font-weight: 600;
            height: 30px;
            width: 98px;
        }
        & button:hover {
            border-color: #383838;
        }
    }
}
</style>
